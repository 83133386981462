import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
      currentPath: typeof window !== 'undefined' ? window.location.pathname: '/'
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
        style={{
          backgroundColor: 'rgb(0, 34, 68)',
        }}
      >
        <div className="container">
          <div className="navbar-brand">
            <Link
              aria-label="go to homepage"
              className="homepage-link"
              to="/"
            >
              <img
                className="profile-image"
                src="/img/Profile.jpg"
                alt="Spencer Brigham"
              />
              <span
                style={{
                  color: '#fff',
                  fontWeight: 'bold',
                  marginLeft: '65px',
                  borderBottom:
                    this.state.currentPath === '/'
                      ? '3px solid #3273dc'
                      : 'none',
                }}
              >
                Spencer Brigham
              </span>
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span style={{ color: '#fff' }} />
              <span style={{ color: '#fff' }} />
              <span style={{ color: '#fff' }} />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
              <Link
                className={classNames('navbar-item', {
                  active: this.state.currentPath === '/about',
                })}
                to="/about"
              >
                <span>About</span>
              </Link>
              <Link
                className={classNames('navbar-item', {
                  active: this.state.currentPath.startsWith('/blog'),
                })}
                to="/blog"
              >
                <span>Blog</span>
              </Link>
              <Link
                className={classNames('navbar-item', {
                  active: this.state.currentPath === '/contact',
                })}
                to="/contact"
              >
                <span>Contact</span>
              </Link>
              {/* <a
                download
                title="Download Resume"
                href="https://drive.google.com/uc?export=download&id=1ErGVUfXWEspPAjaIvMSKsdCbsiiGHZsP"
                className="navbar-item download"
              >
                <span>Resume</span>
                <span
                  className="fas fa-lg"
                  alt="Download Resume"
                  style={{
                    width: '16px',
                    height: '16px',
                    backgroundImage: `url(${resumeDownload})`,
                    backgroundSize: 'contain',
                    color: 'transparent',
                    backgroundRepeat: 'no-repeat',
                    marginLeft: '5px',
                    backgroundPosition: 'center'
                  }}
                >D</span>
              </a> */}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar
